import React, { FC, useState, Suspense, useEffect } from 'react'
import Modal from 'react-responsive-modal'
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import { useLazyLoadQuery } from 'react-relay';
import { CustomerHistory_getCustomerByIdQuery } from './ViewCustomer/__generated__/CustomerHistory_getCustomerByIdQuery.graphql';
import { getCustomerListById } from './ViewCustomer/CustomerHistory';
import { ErrorMessage } from "formik";
import Select from "react-select";
import clsx from "clsx";
import { getDistributor, updateCustomer } from "../../models/_customer";
import toast, { Toaster } from "react-hot-toast";

type Props = {
    currentCustomerId: string;
    handleCustomerClose: () => void;
    customerEditModal: boolean;
    refreshTable?: any
};
const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
 const editCustomerSchema = yup.object().shape({
    name: yup.string().required("Customer name is required"),
    customerCompanyName: yup.string().required("Company name is required"),
    accountType: yup.string().required("Account type is required"),
    isVip: yup.string().required("Is vip is required"),
    distributorId: yup.string().required("Distributor is required"),
    distributor_other: yup.string().when("distributorId", {
      is: "other",
      then: yup.string().required("Other distributor is required"),
    }),
    customerSecondContact: yup.boolean(),
    customerSecondContactName: yup.string().when("customerSecondContact", {
      is: true,
      then: yup.string().required("Contact name is required"),
    }),
    customerSecondContactPhoneNumber: yup.string().when("customerSecondContact", {
        is: true,
        then: yup
        .string()
        .matches(phoneRegExp, "Contact number is not valid")
        .required("Contact phone is required"),
    }),
    customerSecondContactEmail: yup.string().when("customerSecondContact", {
        is: true,
      then: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email"),
    }),
    customerSecondContactNameRole: yup.string().when("customerSecondContact", {
        is: true,
        then: yup.string().required("Contact name role is required"),
    }),
    secondContactPhoneType: yup.string().when("customerSecondContact", {
      is: true,
      then: yup.string().required("Contact phone type is required"),
    }),
  });


export const EditCustomer: FC<Props> = ({
    currentCustomerId,
    customerEditModal,
    handleCustomerClose,
    refreshTable
  }) => {
    const navigate = useNavigate();
  
    if (!currentCustomerId) {
      navigate("/customers/list");
    }
    const customerRecord =
    useLazyLoadQuery<CustomerHistory_getCustomerByIdQuery>(
        getCustomerListById,
        {
          id: currentCustomerId ?? "",
        },
        {
          fetchPolicy: "network-only",
        },
    );
    const customers: any = customerRecord?.getCustomerById ?? [];
    if (customers == null) {
      navigate("/customers/list");
    }

    const [loading, setLoading] = useState(false);
    const [distributorDB, setDistributorDB] = useState([]);
    const accountTypeDB = [
      {
        id: "CHAIN",
        name: "Chain",
      },
      {
        id: "INDIVIDUAL",
        name: "Individual",
      },
    ];
    const isVipDB = [
      {
        id: true,
        name: "Yes",
      },
      {
        id: false,
        name: "No"
      }
    ];
    const statusDB=[
      {
        id: true,
        name:"Active",
      },
      {
        id: false,
        name: "Inactive",
      }
    ]
    const contactTypeDB = [
      {
        id: "PERSONAL",
        name: "Personal",
      },
      {
        id: "OFFICE",
        name: "Office",
      },
    ];
    const fetchData = async () => {
        try {
          const distributorRecord = await getDistributor();
          const distributorIdResults: any = distributorRecord
            ? distributorRecord
            : [];
          distributorIdResults.sort(function (a: any, b: any) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          distributorIdResults.push({
            id: "other",
            name: "Other",
          });
    
          setDistributorDB(distributorIdResults);
        } catch (error) {
          console.log(error);
        }
    };

      useEffect(() => {
        fetchData();
        // eslint-disable-next-line
      }, []);


  return (
    <Suspense>
       <Modal
              open={customerEditModal}
              onClose={() => handleCustomerClose()}
              role="modal"
              center
              classNames={{
                root: "content-wrapper",
              }}
            >
            <Formik
                enableReinitialize
                initialValues= {{
                  id: customers?.id,
                  name: customers?.name ?? "",
                  customerCompanyName: customers?.customerCompanyName ?? "",
                  customerContactName: customers?.customerContactName ?? "",
                  distributorId: customers?.distributorId ?? "",
                  distributorName: customers?.distributor?.name ?? "",
                  distributor_other: customers?.distributor_other ?? "",
                  accountType: customers?.accountType,
                  isVip: customers?.isVip ?? false,
                  contactPhoneType: customers?.typeOfContactPhoneNumber,
                  secondContactPhoneType: customers?.typeOfSecondContactPhoneNumber ?? "",
                  customerContactPhoneNumber: customers?.customerContactPhoneNumber ?? "",
                  customerContactEmail: customers?.customerContactEmail ?? "",
                  customerContactNameRole: customers?.customerContactNameRole ?? "",
                  customerSecondContactName: customers?.customerSecondContactName ?? "",
                  customerSecondContactNameRole:
                    customers?.customerSecondContactNameRole ?? "",
                  customerSecondContactPhoneNumber:
                    customers?.customerSecondContactPhoneNumber ?? "" ,
                  customerSecondContactEmail: customers?.customerSecondContactEmail ?? "",
                  customerSecondContact: customers?.customerSecondContact ?? false,
                  isActive: customers?.isActive
                }}
                validationSchema= {editCustomerSchema}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                    setLoading(true);
                    const updateData: any = {
                      name: values.name,
                      customerCompanyName: values.customerCompanyName,
                      customerContactName: values.customerContactName,
                      customerContactPhoneNumber: values.customerContactPhoneNumber,
                      customerContactEmail: values.customerContactEmail,
                      customerContactNameRole: values.customerContactNameRole,
                      accountType: values.accountType,
                      isVip: values.isVip,
                      typeOfContactPhoneNumber: values.contactPhoneType,
                      distributorId: values.distributorId,
                      distributorOtherInput: values.distributor_other,
                      customerSecondContact: values.customerSecondContact,
                      customerSecondContactName: values.customerSecondContactName,
                      customerSecondContactPhoneNumber:
                        values.customerSecondContactPhoneNumber,
                      customerSecondContactEmail: values.customerSecondContactEmail,
                      customerSecondContactNameRole:
                        values.customerSecondContactNameRole,
                      isActive: values.isActive
                    };
                    if (values.secondContactPhoneType) {
                      updateData["typeOfSecondContactPhoneNumber"] =
                        values.secondContactPhoneType;
                    }
                    if (values.distributorId === "other") {
                      updateData.distributorId = "";
                    }
                    const result = await updateCustomer(values.id, updateData);

                    if (result?.data?.data?.updateCustomer) {
                      setLoading(false)
                      toast.success("Customer updated successfully");
                      handleCustomerClose()
                      resetForm()
                      if(typeof refreshTable !== 'undefined'){
                        refreshTable() 
                      }
                    } else {
                      setLoading(false)
                      const messages = result?.data?.errors.map((e: any) => e.message);
                      toast.error(messages.join(","));
                    }
                }}
            >
            {({
                handleChange,
                handleSubmit,
                values,
                errors,
                touched,
                setFieldValue,
            }) => (
            <form
              id="kt_modal_add_lesson_form"
              className="form"
              onSubmit={handleSubmit} autoComplete="off"
              noValidate
            >
              <div className="modal-header p-2 pb-4 mb-4">
                <h4 className="modal-title fs-1">
                  Edit Customer - 
                  {customers?.uuid}
                </h4>
              </div>
            <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label fs-4 required fw-bold">
                        Customer Name
                        </label>
                        <input
                            className={clsx("form-control", {
                                "is-invalid": Boolean(errors.name) && touched.name,
                            })}
                            name="name"
                            id="name"
                            value={values.name}
                            onChange={handleChange}
                        />
                    <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                  <label className="form-label fs-4 required fw-bold">
                      Company Name
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.customerCompanyName) &&
                          touched.customerCompanyName,
                      })}
                      name="customerCompanyName"
                      id="customerCompanyName"
                      value={values.customerCompanyName}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="customerCompanyName" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                  <label className="form-label fs-4 fw-bold">
                      Contact Name
                    </label>
                    <input
                      name="customerContactName"
                      id="customerContactName"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.customerContactName) &&
                          touched.customerContactName,
                      })}
                      value={values.customerContactName}
                      onChange={handleChange}
                    />
                  
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-3 pt-2">
                  <label className="form-label fs-4 fw-bold ps-1">
                      Contact Phone Type
                    </label>
                    {Array.from(contactTypeDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2" key={id}>
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={id}
                        >
                          <input
                            id="contactPhoneType"
                            className="form-check-input"
                            type="radio"
                            name="contactPhoneType"
                            value={value.id}
                            checked={values.contactPhoneType === value.id}
                            onChange={() =>
                              setFieldValue("contactPhoneType", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                   
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                  <label className="form-label fs-4  fw-bold">
                      Contact Phone
                    </label>
                    <input
                      id="customerContactPhoneNumber"
                      name="customerContactPhoneNumber"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.customerContactPhoneNumber) &&
                          touched.customerContactPhoneNumber,
                      })}
                      value={values.customerContactPhoneNumber}
                      onChange={handleChange}
                    />
                  
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                  <label className="form-label fs-4  fw-bold">
                      Contact Email
                    </label>
                    <input
                      id="customerContactEmail"
                      name="customerContactEmail"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.customerContactEmail) &&
                          touched.customerContactEmail,
                      })}
                      value={values.customerContactEmail}
                      onChange={handleChange}
                    />
                   
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                  <label className="form-label fs-4 required fw-bold">
                      Distributor
                    </label>
                    <Select
                      defaultValue={{
                        label: values.distributorName,
                        value: values.distributorId
                      }}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.distributorId) &&
                          touched.distributorId,
                      })}
                      classNamePrefix="my-react-select"
                      name="distributorId"
                      placeholder=""
                      onChange={(event: any) => {
                        setFieldValue("distributorId", event.value);
                      }}
                      options={Array.from(distributorDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="distributorId" />
                    </div>
                    {values.distributorId === "other" && (
                        <>
                      <label className="form-label fs-4 required fw-bold">
                        Other distributor
                      </label>
                      <input
                        id="distributor_other"
                        name="distributor_other"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.distributor_other) &&
                            touched.distributor_other,
                        })}
                        value={values.distributor_other}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="distributor_other" />
                      </div>
                      </>)}
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                  <label className="form-label fs-4  fw-bold">
                      Contact Name Role
                    </label>
                    <input
                      id="customerContactNameRole"
                      name="customerContactNameRole"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.customerContactNameRole) &&
                          touched.customerContactNameRole,
                      })}
                      value={values.customerContactNameRole}
                      onChange={handleChange}
                    />
                  
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-5 pt-2">
                  <label className="form-label fs-4 required fw-bold ps-1">
                      Account Type
                    </label>
                    {Array.from(accountTypeDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2" key={id}>
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={id}
                        >
                          <input
                            id="accountType"
                            className="form-check-input"
                            type="radio"
                            name="accountType"
                            value={value.id}
                            checked={values.accountType === value.id}
                            onChange={() =>
                              setFieldValue("accountType", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="accountType" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-5 pt-2">
                  <label className="form-label fs-4  fw-bold ps-1">
                      Status
                    </label>
                    {Array.from(statusDB).map((value: any, id) => (
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2" key={id}>
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={id}
                          >
                            <input
                              id="isActive"
                              className="form-check-input"
                              type="radio"
                              name="isActive"
                              value={value.id}
                              checked={values.isActive === value.id}
                              onChange={() =>
                                setFieldValue("isActive", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                      </div>
                    ))}
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-5 pt-2">
                      <label className="form-label fs-4 required fw-bold ps-1">
                        Is VIP
                      </label>
                      {Array.from(isVipDB).map((value: any, id) => {
                        return (
                          <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2" key={id}>
                            <label
                              className="form-check-label fs-5 mb-0 ms-2"
                              key={id}
                            >
                              <input
                                id="isVip"
                                className="form-check-input"
                                type="radio"
                                name="isVip"
                                value={value.id}
                                checked={values.isVip === value.id}
                                onChange={() =>
                                  setFieldValue("isVip", value.id)
                                }
                              />
                              <span className="form-check-label fs-5 mb-0 ms-2">
                                {value.name}
                              </span>
                            </label>
                          </div>
                        )
                      })}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="isVip" />
                      </div>
                    </div>
                  <div className="col-12 ms-2">
                  <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 mt-5 align-items-center">
                      <input
                        id="customerSecondContact"
                        type="checkbox"
                        name="customerSecondContact"
                        className="form-check-input"
                        value="customerSecondContact"
                        checked={values.customerSecondContact}
                        onChange={handleChange}
                      />
                      <span
                        className="form-label fw-bold mt-2 ms-3 fs-3"
                        style={{ cursor: "pointer" }}
                      >
                        Add Second Contact
                      </span>
                      <i
                        className="fas fa-exclamation-circle ms-2 mt-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="Click to add second contact"
                      ></i>
                    </label>
                  </div>

                  {values.customerSecondContact === true ? (
                    <>
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Name
                      </label>
                      <input
                        name="customerSecondContactName"
                        id="customerSecondContactName"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactName) &&
                            touched.customerSecondContactName,
                        })}
                        value={values.customerSecondContactName}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactName" />
                      </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-3 pt-2">
                      <label className="form-label fs-4 required fw-bold ps-1">
                        Contact Phone Type
                      </label>
                      {Array.from(contactTypeDB).map((value: any, id) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2" key={id}> 
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={id}
                          >
                            <input
                              id="secondContactPhoneType"
                              className="form-check-input"
                              type="radio"
                              name="secondContactPhoneType"
                              value={value.id}
                              checked={
                                values.secondContactPhoneType === value.id
                              }
                              onChange={() =>
                                setFieldValue(
                                  "secondContactPhoneType",
                                  value.id
                                )
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="secondContactPhoneType" />
                      </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Phone
                      </label>
                      <input
                        id="customerSecondContactPhoneNumber"
                        name="customerSecondContactPhoneNumber"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactPhoneNumber) &&
                            touched.customerSecondContactPhoneNumber,
                        })}
                        value={values.customerSecondContactPhoneNumber}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactPhoneNumber" />
                      </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label required fs-4 fw-bold">
                        Contact Email
                      </label>
                      <input
                        id="customerSecondContactEmail"
                        name="customerSecondContactEmail"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactEmail) &&
                            touched.customerSecondContactEmail,
                        })}
                        value={values.customerSecondContactEmail}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactEmail" />
                      </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Name Role
                      </label>
                      <input
                        id="customerSecondContactNameRole"
                        name="customerSecondContactNameRole"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactNameRole) &&
                            touched.customerSecondContactNameRole,
                        })}
                        value={values.customerSecondContactNameRole}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactNameRole" />
                      </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div> 
              </div>
               <div className="modal-footer mt-6 p-0 justify-content-start">
                <button
                  type="submit"
                  className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                >
                  {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">UPDATE</span>
                      )}
                </button>
              </div>
              </form>
               )}
               </Formik>
            </Modal> 
            <Toaster
              position="bottom-left"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                className: "",
                duration: 3000,
              }}
            />
    </Suspense>
  )
}
