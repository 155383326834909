import React from "react";
import Moment from "moment";
import _ from "lodash";

const LocationOverview = (props: { locations: any }) => {
  const { locations } = props;

 

  return (
    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9">Location Overview</h3>
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9 mx-xl-4 h-90">
            <div className="card-body">
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Location Region
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.region?.name ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Market Segment
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.marketSegment?.name ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Location Type
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {_.capitalize(
                      _.lowerCase(locations.locationType?.name ?? "")
                    )}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Deployment Manager
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.deploymentManager?.firstName ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Distributor
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.distributor?.name ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Target Launch Date
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations?.targetLaunchDate ? Moment.utc(locations.targetLaunchDate)
                      .local()
                      .format("YYYY-MM-DD") : ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Type Of Contact Phone Number
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {_.capitalize(
                      _.lowerCase(locations.typeOfContactPhoneNumber ?? "")
                    )}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Sampling Recommended Days
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.samplingRecommendedDays ?? ""}
                  </span>
                </div>
              </div>
              <div className="row">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Sampling Recommended Time
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.samplingRecommendedTime ?? ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9 mx-xl-4 h-90">
            <div className="card-body">
            <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  WiFi Name
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations?.wifiCredentials && locations.wifiCredentials.name ? locations.wifiCredentials.name : '-'}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  WiFi Password
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                  {locations?.wifiCredentials && locations.wifiCredentials.password ? locations.wifiCredentials.password : '-'}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  WiFi Signal Strength
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations?.wifiSignalStrength ?? "-"}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Water Pressure
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.waterPressure ?? "-"}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  No Of Blenders
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.noOfBlenders ?? "-"}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  No Of Freezers
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.noOfFreezers ?? "-"}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Freezer Serial Number
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.freezerCredentials?.serialNumber ?? "-"}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Freezer API Key
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.freezerCredentials?.apiKey ?? "-"}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Location Status
                </label>
                <div className="col-lg-6 col-md-6 fv-row ps-0">
                  <span className="fw-bold fs-6 ms-2">
                    {locations.isActive === true ? (
                      <span className="badge badge-light-success fw-bolder me-auto px-4 py-2">
                        Active
                      </span>
                    ) : (
                      <span className="badge badge-light-danger fw-bolder me-auto px-4 py-2">
                        Inactive
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Created At
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {Moment.utc(locations.createdAt)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Created By
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.createdBy?.firstName ?? ""}
                  </span>
                </div>
              </div>
              <div className="row mb-4">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                  Filter Provider
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations.filterProvider?.title ?? ""}
                  </span>
                </div>
              </div>
              <div className="row">
                <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                 Gas Supplier
                </label>
                <div className="col-lg-6 col-md-6 fv-row">
                  <span className="fw-semibold fs-6">
                    {locations?.gasSupplier && locations.gasSupplier?.name  ? locations.gasSupplier?.name : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9 mx-xl-4 h-90">
            <div className="card-body">
              <h4 className="fw-bolder">Second Contact</h4>
              <div className="separator separator-dashed my-4"></div>
              {locations.customerSecondContact === true ? (
                <>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Second Contact Name
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.secondContactName}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Second Contact Phone Number
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.secondContactPhoneNumber}
                      </span>
                      <span className="badge badge-success ms-3">Verified</span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Type Of Second Contact Phone Number
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {_.capitalize(
                          _.lowerCase(locations.typeOfSecondContactPhoneNumber)
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Second Contact Email
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.secondContactEmail}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Second Contact Name Role
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.secondContactNameRole}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="fs-6 text-muted">No records found</div>
              )}
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9 mx-xl-4 h-90">
            <div className="card-body">
              <h4 className="fw-bolder">Service Contact</h4>
              <div className="separator separator-dashed my-4"></div>
              {locations.customerServiceContact === true ? (
                <>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Service Contact Name
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.serviceContactName}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Service Contact Phone Number
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.serviceContactPhoneNumber}
                      </span>
                      <span className="badge badge-success ms-3">Verified</span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Type Of Service Contact Phone Number
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {_.capitalize(
                          _.lowerCase(locations.typeOfServiceContactPhoneNumber)
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Service Contact Email
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.serviceContactEmail}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Service Contact Name Role
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.serviceContactNameRole}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="fs-6 text-muted">No records found</div>
              )}
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9 mx-xl-4 h-90">
            <div className="card-body">
              <h4 className="fw-bolder">IT Contact</h4>
              <div className="separator separator-dashed my-4"></div>
              {locations.customerItContact === true ? (
                <>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      IT Contact Name
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.itContactName}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      IT Contact Phone Number
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.itContactPhoneNumber}
                      </span>
                      <span className="badge badge-success ms-3">Verified</span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Type Of IT Contact Phone Number
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {_.capitalize(
                          _.lowerCase(locations.typeOfItContactPhoneNumber)
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      IT Contact Email
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.itContactEmail}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      IT Contact Name Role
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.itContactNameRole}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="fs-6 text-muted">No records found</div>
              )}
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="card mt-9 mx-xl-4 h-90">
            <div className="card-body">
              <h4 className="fw-bolder">Store Manager Contact</h4>
              <div className="separator separator-dashed my-4"></div>
              {locations.storeManagerContact === true ? (
                <>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Store Manager Contact Name
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.storeManagerContactName}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Store Manager Contact Phone Number
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.storeManagerContactPhoneNumber}
                      </span>
                      <span className="badge badge-success ms-3">Verified</span>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Type Of Store Manager Contact Phone Number
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {_.capitalize(
                          _.lowerCase(
                            locations.typeOfStoreManagerContactPhoneNumber
                          )
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-lg-6 col-md-6 fw-bold text-muted fs-6">
                      Store Manager Contact Email
                    </label>
                    <div className="col-lg-6 col-md-6 fv-row">
                      <span className="fw-semibold fs-6">
                        {locations.storeManagerContactEmail}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="fs-6 text-muted">No records found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationOverview;
