import React, { FC, useState, Suspense, useEffect } from 'react'
import Modal from 'react-responsive-modal'
import { useNavigate } from 'react-router-dom';
import { Formik } from "formik";
import { useLazyLoadQuery } from 'react-relay';
import { ErrorMessage } from "formik";
import Select from "react-select";
import clsx from "clsx";
import toast, { Toaster } from "react-hot-toast";
import { LocationHistory_getLocationQuery } from './ViewLocation/__generated__/LocationHistory_getLocationQuery.graphql';
import { getLocationById } from './ViewLocation/LocationHistory';
import { editLocationSchemas } from './CreateLocationHelper';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import * as _ from "lodash";
import {
  getMarketSegment,
  getRegion,
  getUsers,
  updateLocation,
  getFilterProviders,
  getBusinessModelsList
} from "../../models/_location";
import { getDistributor } from "../../models/_customer";
import Moment from "moment";
import { getLocationTypesList } from '../../models/_locationType';
import { getGasSupplierList } from '../../models/_gasSupplier';

type Props = {
  currentLocationId: string;
  handleLocationClose: () => void;
  locationEditModal: boolean;
  refreshTable?: any
};

export const EditLocation: FC<Props> = ({
  currentLocationId,
  locationEditModal,
  handleLocationClose,
  refreshTable
}) => {
  const navigate = useNavigate();
  if (!currentLocationId) {
    navigate("/locations/list");
  }
  const locationRecord =
    useLazyLoadQuery<LocationHistory_getLocationQuery>(
      getLocationById,
      {
        id: currentLocationId ?? "",
      }, {
      fetchPolicy: "network-only",
    }
    );
  const locations: any = locationRecord?.getLocation ?? [];

  if (locations == null) {
    navigate("/locations/list");
  }
  const [loading, setLoading] = useState(false);
  const [gasSupplierDB, setGasSupplierDB] = useState([]);
  const [distributorDB, setDistributorDB] = useState([]);
  const [marketSegmentDB, setMarketSegmentDB] = useState([]);
  const [businessModelsDB, setBusinessModelsDB] = useState([]);
  const [managerDB, setManagerDB] = useState([]);
  const [locationRegionDB, setLocationRegionDB] = useState([]);
  const [locationTypeDB, setLocationTypeDB] = useState([]);
  const [filterProviderDB, setFilterProviderDB] = useState([])
  const contactTypeDB = [
    {
      id: "PERSONAL",
      name: "Personal",
    },
    {
      id: "OFFICE",
      name: "Office",
    },
  ];


  const fetchData = async () => {
    try {

      const distributorRecord = await getDistributor();
      const distributorIdResults: any = distributorRecord
        ? distributorRecord
        : [];
      distributorIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      distributorIdResults.push({
        id: "other",
        name: "Other",
      });

      const marketSegmentRecord = await getMarketSegment();
      const marketSegmentResults: any = marketSegmentRecord
        ? marketSegmentRecord
        : [];
      marketSegmentResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const locationTypeRecord = await getLocationTypesList();
      const locationTypeResults: any = locationTypeRecord
        ? locationTypeRecord
        : [];
      locationTypeResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const deploymentManagerRecord = await getUsers();
      const deploymentManagerResults: any = deploymentManagerRecord
        ? deploymentManagerRecord
        : [];
      deploymentManagerResults.sort(function (a: any, b: any) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const locationRegionRecord = await getRegion();
      const locationRegionResults: any = locationRegionRecord
        ? locationRegionRecord
        : [];
      locationRegionResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const filterProviderRecord = await getFilterProviders();
      const filterProviderResults: any = filterProviderRecord
        ? filterProviderRecord
        : [];

      filterProviderResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const businessModelsRecord = await getBusinessModelsList();
      const businessModelsResults: any = businessModelsRecord
        ? businessModelsRecord
        : [];

      businessModelsResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const gasSuppliersRecord = await getGasSupplierList();
      const gasSuppliersResults: any = gasSuppliersRecord
        ? gasSuppliersRecord
        : [];

        gasSuppliersResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      setFilterProviderDB(filterProviderResults)
      setDistributorDB(distributorIdResults);
      setMarketSegmentDB(marketSegmentResults);
      setLocationTypeDB(locationTypeResults);
      setManagerDB(deploymentManagerResults);
      setLocationRegionDB(locationRegionResults);
      setBusinessModelsDB(businessModelsResults);
      setGasSupplierDB(gasSuppliersResults);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);


  return (
    <Suspense>
      <Modal
        open={locationEditModal}
        onClose={() => handleLocationClose()}
        role="modal"
        center
        classNames={{
          root: "content-wrapper",
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            credentials: locations?.wifiCredentials ?? "",
            distributorId: locations?.distributorId ?? "",
            address: locations?.address ?? "",
            noOfBlenders: locations?.noOfBlenders ?? "",
            noOfFreezers: locations?.noOfFreezers ?? "",
            samplingRecommendedTime: locations?.samplingRecommendedTime,
            samplingRecommendedDays: locations?.samplingRecommendedDays ?? 0,
            name: locations?.name ?? "",
            contactName: locations?.contactName ?? "",
            contactPhone: locations?.contactPhone ?? "",
            contactEmail: locations?.contactEmail ?? "",
            targetLaunchDate: Moment.utc(locations?.targetLaunchDate ?? "")
              .local()
              .format("YYYY-MM-DD"),
            deploymentManagerId: locations?.deploymentManagerId,
            locationTypeId: locations?.locationTypeId,
            filterProviderId: locations?.filterProviderId,
            businessModelId: locations?.businessModel?.id ?? "",
            gasSupplierId: locations?.gasSupplier?.id ?? "",
            distributorOther: locations?.distributorOtherInput ?? "",
            marketSegmentId: locations?.marketSegmentId,
            customerSecondContact: locations?.customerSecondContact ?? "",
            customerSecondContactName: locations?.secondContactName ?? "",
            customerSecondContactPhoneNumber: locations?.secondContactPhoneNumber ?? "",
            customerSecondContactEmail: locations?.secondContactEmail ?? "",
            customerSecondContactNameRole: locations?.secondContactNameRole ?? "",
            secondContactPhoneType:
              locations?.typeOfSecondContactPhoneNumber ?? "",

            serviceContactPhoneType:
              locations?.typeOfServiceContactPhoneNumber ?? "",
            contactPhoneType: locations?.typeOfContactPhoneNumber ?? "",

            itContact: locations?.customerItContact ?? "",
            itContactName: locations?.itContactName ?? "",
            itContactEmail: locations?.itContactEmail ?? "",
            itContactPhoneNumber: locations?.itContactPhoneNumber ?? "",
            itContactNameRole: locations?.itContactNameRole ?? "",
            itContactPhoneType: locations?.typeOfItContactPhoneNumber ?? "",

            serviceContactName: locations?.serviceContactName ?? "",
            serviceContactEmail: locations?.serviceContactEmail ?? "",
            serviceContactNameRole: locations?.serviceContactNameRole ?? "",
            serviceContactPhoneNumber: locations?.serviceContactPhoneNumber ?? "",
            serviceContact: locations?.customerServiceContact ?? "",

            storeManagerContact: locations?.storeManagerContact,
            storeManagerContactName: locations?.storeManagerContactName ?? "",
            storeManagerContactEmail: locations?.storeManagerContactEmail ?? "",
            storeManagerContactPhoneNumber:
              locations?.storeManagerContactPhoneNumber ?? "",
            typeOfStoreManagerContactPhoneNumber:
              locations?.typeOfStoreManagerContactPhoneNumber ?? "",
            isActive: locations?.isActive,
            regionId: locations?.regionId ?? "",
          }}
          validationSchema={editLocationSchemas}
          onSubmit={async (values, { resetForm, setSubmitting }) => {

            if (!values.filterProviderId) {

              toast.error("Filter provider is required!");
              return
            }
            setLoading(true);
            const updateData: any = {
              id: locations.id,
              name: values.name,
              address: values.address,
              deploymentManagerId: values.deploymentManagerId,
              locationTypeId: values.locationTypeId,
              filterProviderId: values.filterProviderId,

              distributorId: values.distributorId,
              businessModelId: values.locationTypeId === '061adf37-2c28-4ed7-9b54-43336f055fef' ? values?.businessModelId : null,
              gasSupplierId: values.locationTypeId === 'fa01e03d-d8fa-4bfb-8f5e-8fd7c99aee47' ? values?.gasSupplierId : null,
              distributorOtherInput: values.distributorOther,
              marketSegmentId: values.marketSegmentId,
              noOfBlenders: parseFloat(values.noOfBlenders),
              noOfFreezers: parseFloat(values.noOfFreezers),
              regionId: values.regionId,
              isActive: locations.isActive,
              latitude: locations.latitude,
              longitude: locations.longitude,
              city: locations.city,
              state: locations.state,
              contactName: values.contactName,
              contactPhone: values.contactPhone,
              contactEmail: values.contactEmail,
              targetLaunchDate: values.targetLaunchDate,
              typeOfContactPhoneNumber: values.contactPhoneType,
              itContactEmail: values.itContactEmail,
              itContactName: values.itContactName,
              itContactNameRole: values.itContactNameRole,
              secondContactEmail: values.customerSecondContactEmail,
              secondContactName: values.customerSecondContactName,
              secondContactNameRole: values.customerSecondContactNameRole,
              secondContactPhoneNumber: values.customerSecondContactPhoneNumber,
              customerItContact: values.itContact,
              serviceContactName: values.serviceContactName,
              serviceContactEmail: values.serviceContactEmail,
              serviceContactNameRole: values.serviceContactNameRole,
              serviceContactPhoneNumber: values.serviceContactPhoneNumber,
              itContactPhoneNumber: values.itContactPhoneNumber,
              customerServiceContact: values.serviceContact,
              customerSecondContact: values.customerSecondContact,
              storeManagerContact: values.storeManagerContact,
              storeManagerContactName: values.storeManagerContactName,
              storeManagerContactEmail: values.storeManagerContactEmail,
              storeManagerContactPhoneNumber: values.storeManagerContactPhoneNumber,
              typeOfStoreManagerContactPhoneNumber:
                values.typeOfStoreManagerContactPhoneNumber,
            };
            if (values.samplingRecommendedDays !== locations.samplingRecommendedDays) {
              updateData["samplingRecommendedDays"] = parseFloat(
                values.samplingRecommendedDays
              );
            }
            if (values.samplingRecommendedTime === null) {
              updateData["samplingRecommendedTime"] =
                values.samplingRecommendedTime;
            } else if (
              values.samplingRecommendedTime !== locations.samplingRecommendedTime
            ) {
              updateData["samplingRecommendedTime"] = Moment.utc(
                values.samplingRecommendedTime
              )
                .local()
                .format("HH:mm");
            }

            if (values.secondContactPhoneType) {
              updateData["typeOfSecondContactPhoneNumber"] =
                values.secondContactPhoneType;
            }
            if (values.itContactPhoneType) {
              updateData["typeOfItContactPhoneNumber"] =
                values.itContactPhoneType;
            }
            if (values.serviceContactPhoneType) {
              updateData["typeOfServiceContactPhoneNumber"] =
                values.serviceContactPhoneType;
            }
            if (values.typeOfStoreManagerContactPhoneNumber) {
              updateData["typeOfStoreManagerContactPhoneNumber"] =
                values.typeOfStoreManagerContactPhoneNumber;
            }
            const result = await updateLocation(updateData);
            if (result?.data?.data?.updateLocation) {
              toast.success("Location updated successfully");
              setLoading(false)
              handleLocationClose()
              resetForm()
              if (typeof refreshTable !== 'undefined') {
                refreshTable()
              }
            } else {
              setLoading(false)
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <form
              id="kt_modal_add_lesson_form"
              className="form"
              onSubmit={handleSubmit} autoComplete="off"
              noValidate
            >
              <div className="modal-header p-2 pb-4 mb-4">
                <h4 className="modal-title fs-1">
                  Edit Location - {locations?.uuid ?? ""}
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Location Name
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.name) && touched.name,
                      })}
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Location Address
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.address) && touched.address,
                      })}
                      name="address"
                      id="address"
                      value={values.address}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="address" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold">
                      Contact Name
                    </label>
                    <input
                      id="contactName"
                      name="contactName"
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.contactName) && touched.contactName,
                      })}
                      value={values.contactName}
                      onChange={handleChange}
                    />

                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold ">
                      Contact Email
                    </label>
                    <input
                      id="contactEmail"
                      name="contactEmail"
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.contactEmail) && touched.contactEmail,
                      })}
                      value={values.contactEmail}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="contactEmail" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold ">
                      Contact Phone
                    </label>
                    <input
                      id="contactPhone"
                      name="contactPhone"
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.contactPhone) && touched.contactPhone,
                      })}
                      value={values.contactPhone}
                      onChange={handleChange}
                    />

                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 d-block  fw-bold ps-1">
                      Contact Phone Type
                    </label>
                    {Array.from(contactTypeDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={id}
                        >
                          <input
                            id="contactPhoneType"
                            className="form-check-input"
                            type="radio"
                            name="contactPhoneType"
                            value={value.id}
                            checked={value.id === values.contactPhoneType}
                            onChange={() =>
                              setFieldValue("contactPhoneType", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}

                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Location Region
                    </label>
                    <Select
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.regionId) && touched.regionId,
                      })}
                      classNamePrefix="my-react-select"
                      name="regionId"
                      placeholder=""
                      defaultValue={{
                        label: locations?.region?.name ?? "",
                        value: values.regionId,
                      }}
                      options={Array.from(locationRegionDB).map(
                        (value: any) => {
                          return {
                            value: value.id,
                            label: value.name,
                          };
                        }
                      )}
                      onChange={(e: any) => {
                        setFieldValue("regionId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="regionId" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Market Segment
                    </label>
                    <Select
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.marketSegmentId) &&
                          touched.marketSegmentId,
                      })}
                      defaultValue={{
                        label: locations?.marketSegment?.name ?? "",
                        value: values.marketSegmentId,
                      }}
                      classNamePrefix="my-react-select"
                      name="marketSegmentId"
                      placeholder=""
                      options={Array.from(marketSegmentDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("marketSegmentId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="marketSegmentId" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Location Type
                    </label>
                    <Select
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.locationTypeId) &&
                          touched.locationTypeId,
                      })}
                      defaultValue={{
                        label: _.capitalize(_.lowerCase(locations?.locationType?.name)) ?? "",
                        value: values.locationTypeId,
                      }}
                      classNamePrefix="my-react-select"
                      name="locationTypeId"
                      placeholder=""
                      options={Array.from(locationTypeDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: _.capitalize(_.lowerCase(value.name)),
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("locationTypeId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="locationTypeId" />
                    </div>
                  </div>

                  {values.locationTypeId === '061adf37-2c28-4ed7-9b54-43336f055fef' && (
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className={'form-label fs-4 fw-bold' + (values.locationTypeId === '061adf37-2c28-4ed7-9b54-43336f055fef' ? ' required' : '')}>
                        Business Model
                      </label>
                      <Select
                        className={clsx("react-select", values.locationTypeId === '061adf37-2c28-4ed7-9b54-43336f055fef' ? {
                          "is-invalid":
                            Boolean(errors.businessModelId) &&
                            touched.businessModelId,
                        } : '')}
                        classNamePrefix="my-react-select"
                        name="businessModelId"
                        placeholder=""
                        defaultValue={{
                          label: locations?.businessModel?.name ?? "",
                          value: values.businessModelId,
                        }}
                        options={Array.from(businessModelsDB).map((value: any) => {
                          return {
                            value: value.id,
                            label: value.name,
                          };
                        })}
                        onChange={(e: any) => {
                          setFieldValue("businessModelId", e.value);
                        }}
                      />
                      {values.locationTypeId === '061adf37-2c28-4ed7-9b54-43336f055fef' && (
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="businessModelId" />
                        </div>
                      )}
                    </div>
                  )}

                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Deployment Manager
                    </label>
                    <Select
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.deploymentManagerId) &&
                          touched.deploymentManagerId,
                      })}
                      defaultValue={{
                        label: locations?.deploymentManager?.firstName ?? "",
                        value: values.deploymentManagerId,
                      }}
                      classNamePrefix="my-react-select"
                      placeholder=""
                      name="deploymentManagerId"
                      options={Array.from(managerDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.firstName,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("deploymentManagerId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="deploymentManagerId" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Distributor
                    </label>
                    <Select
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="distributorId"
                      defaultValue={{
                        label: locations?.distributor?.name ?? "",
                        value: values.distributorId,
                      }}
                      options={Array.from(distributorDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("distributorId", e.value);
                      }}
                    />
                    {values.distributorId === "other" ? (
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <label className="form-label fs-4 required fw-bold">
                          Other Distributor
                        </label>
                        <input
                          id="distributorOther"
                          name="distributorOther"
                          value={values.distributorOther}
                          className="form-control"
                          onChange={(e: any) => {
                            setFieldValue("distributorOther", e.target.value);
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {values.marketSegmentId === 'fa01e03d-d8fa-4bfb-8f5e-8fd7c99aee47' && (
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className={'form-label fs-4 fw-bold' + (values.marketSegmentId === 'fa01e03d-d8fa-4bfb-8f5e-8fd7c99aee47' ? ' required' : '')}>
                      Gas Supplier
                      </label>
                      <Select
                        className={clsx("react-select", values.marketSegmentId === 'fa01e03d-d8fa-4bfb-8f5e-8fd7c99aee47' ? {
                          "is-invalid":
                            Boolean(errors.gasSupplierId) &&
                            touched.gasSupplierId,
                        } : '')}
                        classNamePrefix="my-react-select"
                        name="gasSupplierId"
                        placeholder=""
                        defaultValue={{
                          label: locations?.gasSupplier?.name ?? "",
                          value: values.gasSupplierId,
                        }}
                        options={Array.from(gasSupplierDB).map((value: any) => {
                          return {
                            value: value.id,
                            label: value.name,
                          };
                        })}
                        onChange={(e: any) => {
                          setFieldValue("gasSupplierId", e.value);
                        }}
                      />
                      {values.marketSegmentId === 'fa01e03d-d8fa-4bfb-8f5e-8fd7c99aee47' && (
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="gasSupplierId" />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Filter Provider
                    </label>
                    <Select
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.filterProviderId) &&
                          touched.filterProviderId,
                      })}
                      defaultValue={{
                        label: locations?.filterProvider?.title ?? "",
                        value: values.filterProviderId,
                      }}
                      classNamePrefix="my-react-select"
                      placeholder=""
                      name="filterProviderId"
                      options={Array.from(filterProviderDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("filterProviderId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="filterProviderId" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold">
                      Sampling Recommended Time
                    </label>
                    <Datetime
                      closeOnSelect
                      dateFormat={false}
                      timeFormat="HH:mm"
                      className="date-control"
                      value={values.samplingRecommendedTime}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("samplingRecommendedTime", dt);
                      }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold">
                      Sampling Recommended Days
                    </label>
                    <input
                      type="number"
                      id="samplingRecommendedDays"
                      name="samplingRecommendedDays"
                      className="form-control"
                      value={values.samplingRecommendedDays}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold">
                      No.of Freezers
                    </label>
                    <input
                      id="noOfFreezers"
                      name="noOfFreezers"
                      type="number"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.noOfFreezers) && touched.noOfFreezers,
                      })}
                      value={values.noOfFreezers}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="noOfFreezers" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold">
                      No.of Blenders
                    </label>
                    <input
                      id="noOfBlenders"
                      name="noOfBlenders"
                      type="number"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.noOfBlenders) && touched.noOfBlenders,
                      })}
                      value={values.noOfBlenders}
                      onChange={handleChange}
                    />

                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold">
                      Target Launch Date
                    </label>
                    <Datetime
                      dateFormat="YYYY-MM-DD"
                      closeOnSelect
                      timeFormat={false}
                      value={values.targetLaunchDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("targetLaunchDate", dt);
                      }}
                    />
                  </div>
                  {/* <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold">
                      WiFi-Credentials
                    </label>
                    <input
                      id="credentials"
                      name="credentials"
                      className="text-transform form-control"
                      value={values.credentials}
                      onChange={handleChange}
                    />
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 mt-5 align-items-center">
                      <input
                        id="customerSecondContact"
                        type="checkbox"
                        name="customerSecondContact"
                        className="form-check-input"
                        value="customerSecondContact"
                        checked={values.customerSecondContact}
                        onChange={handleChange}
                      />
                      <span
                        className="form-label fw-bold mt-2 ms-3 fs-3"
                        style={{ cursor: "pointer" }}
                      >
                        Add Second Contact
                      </span>
                      <i
                        className="fas fa-exclamation-circle ms-2 mt-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="Click to add second contact"
                      ></i>
                    </label>
                  </div>
                </div>
                {values.customerSecondContact === true ? (
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Name
                      </label>
                      <input
                        autoComplete="off"
                        name="customerSecondContactName"
                        id="customerSecondContactName"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactName) &&
                            touched.customerSecondContactName,
                        })}
                        value={values.customerSecondContactName}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactName" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 d-block required fw-bold ps-1">
                        Contact Phone Type
                      </label>
                      {Array.from(contactTypeDB).map((value: any, id) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                          <label className="form-check-label fs-5 mb-0 ms-2" key={id}>
                            <input
                              id="secondContactPhoneType"
                              className="form-check-input"
                              type="radio"
                              name="secondContactPhoneType"
                              value={value.id}
                              checked={values.secondContactPhoneType === value.id}
                              onChange={() =>
                                setFieldValue("secondContactPhoneType", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="secondContactPhoneType" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Phone
                      </label>
                      <input
                        autoComplete="off"
                        id="customerSecondContactPhoneNumber"
                        name="customerSecondContactPhoneNumber"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactPhoneNumber) &&
                            touched.customerSecondContactPhoneNumber,
                        })}
                        value={values.customerSecondContactPhoneNumber}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactPhoneNumber" />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label required fs-4 fw-bold">
                        Contact Email
                      </label>
                      <input
                        autoComplete="off"
                        id="customerSecondContactEmail"
                        name="customerSecondContactEmail"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactEmail) &&
                            touched.customerSecondContactEmail,
                        })}
                        value={values.customerSecondContactEmail}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactEmail" />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Name Role
                      </label>
                      <input
                        autoComplete="off"
                        id="customerSecondContactNameRole"
                        name="customerSecondContactNameRole"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.customerSecondContactNameRole) &&
                            touched.customerSecondContactNameRole,
                        })}
                        value={values.customerSecondContactNameRole}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="customerSecondContactNameRole" />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 align-items-center">
                      <input
                        id="itContact"
                        type="checkbox"
                        name="itContact"
                        className="form-check-input"
                        value="itContact"
                        checked={values.itContact}
                        onChange={handleChange}
                      />
                      <span
                        className="form-label fw-bold mt-2 ms-3 fs-3"
                        style={{ cursor: "pointer" }}
                      >
                        Add IT Contact
                      </span>
                      <i
                        className="fas fa-exclamation-circle ms-2 mt-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="Click to add it contact"
                      ></i>
                    </label>
                  </div>
                </div>
                {values.itContact === true ? (
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Name
                      </label>
                      <input
                        autoComplete="off"
                        name="itContactName"
                        id="itContactName"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.itContactName) && touched.itContactName,
                        })}
                        value={values.itContactName}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="itContactName" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 d-block required fw-bold ps-1">
                        Contact Phone Type
                      </label>
                      {Array.from(contactTypeDB).map((value: any, id) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                          <label className="form-check-label fs-5 mb-0 ms-2" key={id}>
                            <input
                              id="itContactPhoneType"
                              className="form-check-input"
                              type="radio"
                              name="itContactPhoneType"
                              value={value.id}
                              checked={values.itContactPhoneType === value.id}
                              onChange={() =>
                                setFieldValue("itContactPhoneType", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="itContactPhoneType" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Phone
                      </label>
                      <input
                        autoComplete="off"
                        id="itContactPhoneNumber"
                        name="itContactPhoneNumber"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.itContactPhoneNumber) &&
                            touched.itContactPhoneNumber,
                        })}
                        value={values.itContactPhoneNumber}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="itContactPhoneNumber" />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label required fs-4 fw-bold">
                        Contact Email
                      </label>
                      <input
                        autoComplete="off"
                        id="itContactEmail"
                        name="itContactEmail"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.itContactEmail) && touched.itContactEmail,
                        })}
                        value={values.itContactEmail}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="itContactEmail" />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Contact Name Role
                      </label>
                      <input
                        autoComplete="off"
                        id="itContactNameRole"
                        name="itContactNameRole"
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.itContactNameRole) &&
                            touched.itContactNameRole,
                        })}
                        value={values.itContactNameRole}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="itContactNameRole" />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 align-items-center">
                      <input
                        id="serviceContact"
                        type="checkbox"
                        name="serviceContact"
                        className="form-check-input"
                        value="serviceContact"
                        checked={values.serviceContact}
                        onChange={handleChange}
                      />
                      <span
                        className="form-label fw-bold mt-2 ms-3 fs-3"
                        style={{ cursor: "pointer" }}
                      >
                        Add Service Contact
                      </span>
                      <i
                        className="fas fa-exclamation-circle ms-2 mt-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="Click to add service contact"
                      ></i>
                    </label>
                  </div>
                  {values.serviceContact === true ? (
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label fs-4 required fw-bold">
                          Contact Name
                        </label>
                        <input
                          autoComplete="off"
                          name="serviceContactName"
                          id="serviceContactName"
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.serviceContactName) &&
                              touched.serviceContactName,
                          })}
                          value={values.serviceContactName}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="serviceContactName" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label d-block fs-4 required fw-bold ps-1">
                          Contact Phone Type
                        </label>
                        {Array.from(contactTypeDB).map((value: any, id) => (
                          <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                            <label className="form-check-label fs-5 mb-0 ms-2" key={id}>
                              <input
                                id="serviceContactPhoneType"
                                className="form-check-input"
                                type="radio"
                                name="serviceContactPhoneType"
                                value={value.id}
                                checked={values.serviceContactPhoneType === value.id}
                                onChange={() =>
                                  setFieldValue("serviceContactPhoneType", value.id)
                                }
                              />
                              <span className="form-check-label fs-5 mb-0 ms-2">
                                {value.name}
                              </span>
                            </label>
                          </div>
                        ))}
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="serviceContactPhoneType" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label fs-4 required fw-bold">
                          Contact Phone
                        </label>
                        <input
                          autoComplete="off"
                          id="serviceContactPhoneNumber"
                          name="serviceContactPhoneNumber"
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.serviceContactPhoneNumber) &&
                              touched.serviceContactPhoneNumber,
                          })}
                          value={values.serviceContactPhoneNumber}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="serviceContactPhoneNumber" />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label required fs-4 fw-bold">
                          Contact Email
                        </label>
                        <input
                          autoComplete="off"
                          id="serviceContactEmail"
                          name="serviceContactEmail"
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.serviceContactEmail) &&
                              touched.serviceContactEmail,
                          })}
                          value={values.serviceContactEmail}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="serviceContactEmail" />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label fs-4 required fw-bold">
                          Contact Name Role
                        </label>
                        <input
                          autoComplete="off"
                          id="serviceContactNameRole"
                          name="serviceContactNameRole"
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.serviceContactNameRole) &&
                              touched.serviceContactNameRole,
                          })}
                          value={values.serviceContactNameRole}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="serviceContactNameRole" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                    <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 align-items-center">
                      <input
                        id="storeManagerContact"
                        type="checkbox"
                        name="storeManagerContact"
                        className="form-check-input"
                        value="storeManagerContact"
                        checked={values.storeManagerContact}
                        onChange={handleChange}
                      />
                      <span
                        className="form-label fw-bold mt-2 ms-3 fs-3"
                        style={{ cursor: "pointer" }}
                      >
                        Add Store Manager Contact
                      </span>
                      <i
                        className="fas fa-exclamation-circle ms-2 mt-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="Click to add store manager contact"
                      ></i>
                    </label>
                  </div>
                  {values.storeManagerContact === true ? (
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label d-block fs-4 required fw-bold ps-1">
                          Contact Name
                        </label>
                        <input
                          autoComplete="off"
                          name="storeManagerContactName"
                          id="storeManagerContactName"
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.storeManagerContactName) &&
                              touched.storeManagerContactName,
                          })}
                          value={values.storeManagerContactName}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="storeManagerContactName" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label d-block fs-4 required fw-bold ps-1">
                          Contact Phone Type
                        </label>
                        {Array.from(contactTypeDB).map((value: any, id) => (
                          <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                            <label className="form-check-label fs-5 mb-0 ms-2" key={id}>
                              <input
                                id="typeOfStoreManagerContactPhoneNumber"
                                className="form-check-input"
                                type="radio"
                                name="typeOfStoreManagerContactPhoneNumber"
                                value={value.id}
                                checked={
                                  values.typeOfStoreManagerContactPhoneNumber === value.id
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "typeOfStoreManagerContactPhoneNumber",
                                    value.id
                                  )
                                }
                              />
                              <span className="form-check-label fs-5 mb-0 ms-2">
                                {value.name}
                              </span>
                            </label>
                          </div>
                        ))}
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="typeOfStoreManagerContactPhoneNumber" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label fs-4 required fw-bold">
                          Contact Phone
                        </label>
                        <input
                          autoComplete="off"
                          id="storeManagerContactPhoneNumber"
                          name="storeManagerContactPhoneNumber"
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.storeManagerContactPhoneNumber) &&
                              touched.storeManagerContactPhoneNumber,
                          })}
                          value={values.storeManagerContactPhoneNumber}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="storeManagerContactPhoneNumber" />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label required fs-4 fw-bold">
                          Contact Email
                        </label>
                        <input
                          autoComplete="off"
                          id="storeManagerContactEmail"
                          name="storeManagerContactEmail"
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.storeManagerContactEmail) &&
                              touched.storeManagerContactEmail,
                          })}
                          value={values.storeManagerContactEmail}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="storeManagerContactEmail" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="modal-footer mt-6 p-0 justify-content-start">
                <button
                  type="submit"
                  className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                >
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!loading && (
                    <span className="indicator-label">UPDATE</span>
                  )}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  )
}
