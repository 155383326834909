import * as yup from "yup";

export interface ICreateLocation {
  name: string;
  customerId: string;
  regionId: string;
  locationTypeId: string;
  filterProviderId: string;
  marketSegmentId: string;
  businessModelId: string;
  gasSupplierId:string;
  deploymentManagerId: string;
  targetLaunchDate: Date;
  samplingRecommendedDays?: string;
  samplingRecommendedTime?: string;
  noOfBlenders: 1;
  noOfFreezers: 1;
  isActive: true;
  distributorOther: string;
  address: string;
  credentials: string;

  customerSecondContact: boolean;
  customerSecondContactName: string;
  customerSecondContactPhoneNumber: string;
  customerSecondContactEmail: string;
  customerSecondContactNameRole: string;
  secondContactPhoneType: string | null;

  itContact: boolean;
  itContactName: string;
  itContactPhoneNumber: string;
  itContactEmail: string;
  itContactNameRole: string;
  itContactPhoneType: string | null;

  serviceContact: boolean;
  serviceContactName: string;
  serviceContactPhoneNumber: string;
  serviceContactEmail: string;
  serviceContactNameRole: string;
  serviceContactPhoneType: string | null;

  storeManagerContact: boolean;
  storeManagerContactName: string;
  storeManagerContactPhoneNumber: string;
  storeManagerContactEmail: string;
  typeOfStoreManagerContactPhoneNumber: string | null;
}

const phoneRegExp = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

const createLocationSchemas = yup.object({
  name: yup.string().required("Location name is required"),
  marketSegmentId: yup.string().required("Market segment is required"),
  deploymentManagerId: yup.string().required("Deployment manager is required"),
  locationTypeId: yup.string().required("Location type is required"),
  filterProviderId: yup.string().required("Filter provider  is required"),
  regionId: yup.string().required("Location region is required"),
  address: yup.string().required("Location address is required"),
  noOfBlenders: yup.number().required("Total blenders is required"),
  noOfFreezers: yup.number().required("Total freezers is required"),
  businessModelId: yup.string().when(['locationTypeId'], {
    is: (locationTypeId: any) => locationTypeId === '061adf37-2c28-4ed7-9b54-43336f055fef',
    then: yup.string().required("Business model is required"),
  }),
  gasSupplierId: yup.string().when(['marketSegmentId'], {
    is: (marketSegmentId: any) => marketSegmentId === 'fa01e03d-d8fa-4bfb-8f5e-8fd7c99aee47',
    then: yup.string().required("Gas Supplier is required"),
  }),
  customerSecondContact: yup.boolean(),
  secondContactPhoneType: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact phone type is required"),
  }),
  customerSecondContactName: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  customerSecondContactPhoneNumber: yup.string().when("customerSecondContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  customerSecondContactEmail: yup.string().when("customerSecondContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  customerSecondContactNameRole: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact name role is required"),
  }),

  itContact: yup.boolean(),
  itContactName: yup.string().when("itContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  itContactPhoneNumber: yup.string().when("itContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  itContactEmail: yup.string().when("itContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  itContactNameRole: yup.string().when("itContact", {
    is: true,
    then: yup.string().required("Contact name role is required"),
  }),
  itContactPhoneType: yup.string().when("itContact", {
    is: true,
    then: yup.string().required("Contact phone type is required"),
  }),

  serviceContact: yup.boolean(),
  serviceContactName: yup.string().when("serviceContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  serviceContactPhoneNumber: yup.string().when("serviceContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  serviceContactEmail: yup.string().when("serviceContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  serviceContactNameRole: yup.string().when("serviceContact", {
    is: true,
    then: yup.string().required("Contact name role is required"),
  }),
  serviceContactPhoneType: yup.string().when("serviceContact", {
    is: true,
    then: yup.string().required("Contact phone type is required"),
  }),

  storeManagerContact: yup.boolean(),
  storeManagerContactName: yup.string().when("storeManagerContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  storeManagerContactPhoneNumber: yup.string().when("storeManagerContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  storeManagerContactEmail: yup.string().when("storeManagerContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  typeOfStoreManagerContactPhoneNumber: yup
    .string()
    .when("storeManagerContact", {
      is: true,
      then: yup.string().required("Contact phone type is required"),
    }),
});

const editLocationSchemas = yup.object({
  name: yup.string().required("Location name is required"),

  contactEmail: yup.string().email("Enter a valid email"),
  marketSegmentId: yup.string().required("Market segment is required"),
  deploymentManagerId: yup.string().required("Deployment manager is required"),
  locationTypeId: yup.string().required("Location type is required"),
  regionId: yup.string().required("Location region is required"),
  address: yup.string().required("Location address is required"),

  distributorId: yup.string().required("Distributor is required"),
  distributorOther: yup.string().when("distributorId", {
    is: "other",
    then: yup.string().required("Other distributor is required"),
  }),
  customerSecondContact: yup.boolean(),
  secondContactPhoneType: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact phone type is required"),
  }),
  customerSecondContactName: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  customerSecondContactPhoneNumber: yup.string().when("customerSecondContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  customerSecondContactEmail: yup.string().when("customerSecondContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  customerSecondContactNameRole: yup.string().when("customerSecondContact", {
    is: true,
    then: yup.string().required("Contact name role is required"),
  }),

  itContact: yup.boolean(),
  itContactName: yup.string().when("itContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  itContactPhoneNumber: yup.string().when("itContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  itContactEmail: yup.string().when("itContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  itContactNameRole: yup.string().when("itContact", {
    is: true,
    then: yup.string().required("Contact name role is required"),
  }),
  itContactPhoneType: yup.string().when("itContact", {
    is: true,
    then: yup.string().required("Contact phone type is required"),
  }),

  serviceContact: yup.boolean(),
  serviceContactName: yup.string().when("serviceContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  serviceContactPhoneNumber: yup.string().when("serviceContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  serviceContactEmail: yup.string().when("serviceContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  serviceContactNameRole: yup.string().when("serviceContact", {
    is: true,
    then: yup.string().required("Contact name role is required"),
  }),
  serviceContactPhoneType: yup.string().when("serviceContact", {
    is: true,
    then: yup.string().required("Contact phone type is required"),
  }),

  storeManagerContact: yup.boolean(),
  storeManagerContactName: yup.string().when("storeManagerContact", {
    is: true,
    then: yup.string().required("Contact name is required"),
  }),
  storeManagerContactPhoneNumber: yup.string().when("storeManagerContact", {
    is: true,
    then: yup
      .string()
      .matches(phoneRegExp, "Contact number is not valid")
      .required("Contact phone is required"),
  }),
  storeManagerContactEmail: yup.string().when("storeManagerContact", {
    is: true,
    then: yup
      .string()
      .required("Contact email is required")
      .email("Enter a valid email"),
  }),
  typeOfStoreManagerContactPhoneNumber: yup
    .string()
    .when("storeManagerContact", {
      is: true,
      then: yup.string().required("Contact phone type is required"),
    }),
  businessModelId: yup.string().when(['locationTypeId'], {
    is: (locationTypeId: any) => locationTypeId === '061adf37-2c28-4ed7-9b54-43336f055fef',
    then: yup.string().required("Business model is required"),
  }),
  gasSupplierId: yup.string().when(['marketSegmentId'], {
    is: (marketSegmentId: any) => marketSegmentId === 'fa01e03d-d8fa-4bfb-8f5e-8fd7c99aee47',
    then: yup.string().required("Gas Supplier is required"),
  }),
});

const inits: ICreateLocation = {
  name: "",
  customerId: "",
  regionId: "",
  locationTypeId: "",
  filterProviderId: "",
  marketSegmentId: "",
  businessModelId: "",
  gasSupplierId:"",
  deploymentManagerId: "",
  targetLaunchDate: new Date(),
  samplingRecommendedDays: "",
  samplingRecommendedTime: "",
  noOfBlenders: 1,
  noOfFreezers: 1,
  isActive: true,
  distributorOther: "",
  address: "",
  credentials: "",

  customerSecondContact: false,
  customerSecondContactName: "",
  customerSecondContactPhoneNumber: "",
  customerSecondContactEmail: "",
  customerSecondContactNameRole: "",
  secondContactPhoneType: "",

  itContact: false,
  itContactName: "",
  itContactPhoneNumber: "",
  itContactEmail: "",
  itContactNameRole: "",
  itContactPhoneType: "",

  serviceContact: false,
  serviceContactName: "",
  serviceContactPhoneNumber: "",
  serviceContactEmail: "",
  serviceContactNameRole: "",
  serviceContactPhoneType: "",

  storeManagerContact: false,
  storeManagerContactName: "",
  storeManagerContactPhoneNumber: "",
  storeManagerContactEmail: "",
  typeOfStoreManagerContactPhoneNumber: "",
};

export { createLocationSchemas, editLocationSchemas, inits };
