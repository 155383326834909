import React from "react";
import { ErrorMessage } from "formik";
import clsx from "clsx";
import { Tooltip } from "react-tooltip";

export const CreateLocation2 = (props: {
  values: any;
  errors: any;
  touched: any;
  handleChange: any;
  setFieldValue: any;
}) => {
  const { values } = props;
  const { errors } = props;
  const { touched } = props;
  const { handleChange } = props;
  const { setFieldValue } = props;

  const contactTypeDB = [
    {
      id: "PERSONAL",
      name: "Personal",
    },
    {
      id: "OFFICE",
      name: "Office",
    },
  ];

  return (
    <div>
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-2 pe-xl-5 ps-xl-5 pb-2">
          <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 mt-5 align-items-center">
            <input
              id="customerSecondContact"
              type="checkbox"
              name="customerSecondContact"
              className="form-check-input"
              value="customerSecondContact"
              checked={values.customerSecondContact}
              onChange={handleChange}
            />
            <span
              className="addSecondContact form-label fw-bold mt-2 ms-3 fs-3"
              style={{ cursor: "pointer" }}
            >
              Add Second Contact
            </span>
            <i
              className="addSecondContact fas fa-exclamation-circle ms-2 mt-1 fs-7"
              data-bs-toggle="tooltip"

            ></i>
            <Tooltip anchorSelect=".addSecondContact" className="tooltip-dashboard">
            Click here to include details for an additional contact person
            </Tooltip>
          </label>
        </div>
      </div>

      {values.customerSecondContact === true ? (
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Name
            </label>
            <input
              autoComplete="off"
              name="customerSecondContactName"
              id="customerSecondContactName"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.customerSecondContactName) &&
                  touched.customerSecondContactName,
              })}
              value={values.customerSecondContactName}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="customerSecondContactName" />
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-3">
            <label className="form-label fs-4 d-block required fw-bold ps-1">
              Contact Phone Type
            </label>
            {Array.from(contactTypeDB).map((value: any, id) => (
              <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                <label className="form-check-label fs-5 mb-0 ms-2" key={id}>
                  <input
                    id="secondContactPhoneType"
                    className="form-check-input"
                    type="radio"
                    name="secondContactPhoneType"
                    value={value.id}
                    checked={values.secondContactPhoneType === value.id}
                    onChange={() =>
                      setFieldValue("secondContactPhoneType", value.id)
                    }
                  />
                  <span className="form-check-label fs-5 mb-0 ms-2">
                    {value.name}
                  </span>
                </label>
              </div>
            ))}
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="secondContactPhoneType" />
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Phone
            </label>
            <input
              autoComplete="off"
              id="customerSecondContactPhoneNumber"
              name="customerSecondContactPhoneNumber"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.customerSecondContactPhoneNumber) &&
                  touched.customerSecondContactPhoneNumber,
              })}
              value={values.customerSecondContactPhoneNumber}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="customerSecondContactPhoneNumber" />
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label required fs-4 fw-bold">
              Contact Email
            </label>
            <input
              autoComplete="off"
              id="customerSecondContactEmail"
              name="customerSecondContactEmail"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.customerSecondContactEmail) &&
                  touched.customerSecondContactEmail,
              })}
              value={values.customerSecondContactEmail}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="customerSecondContactEmail" />
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Name Role
            </label>
            <input
              autoComplete="off"
              id="customerSecondContactNameRole"
              name="customerSecondContactNameRole"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.customerSecondContactNameRole) &&
                  touched.customerSecondContactNameRole,
              })}
              value={values.customerSecondContactNameRole}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="customerSecondContactNameRole" />
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-2 pe-xl-5 ps-xl-5 pb-2">
          <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 align-items-center">
            <input
              id="itContact"
              type="checkbox"
              name="itContact"
              className="form-check-input"
              value="itContact"
              checked={values.itContact}
              onChange={handleChange}
            />
            <span
              className="addItContact form-label fw-bold mt-2 ms-3 fs-3"
              style={{ cursor: "pointer" }}
            >
              Add IT Contact
            </span>
            <i
              className="addItContact fas fa-exclamation-circle ms-2 mt-1 fs-7"
              data-bs-toggle="tooltip"
            ></i>
             <Tooltip anchorSelect=".addItContact" className="tooltip-dashboard">
             Click here to add the contact information of the IT (Information Technology) representative of the customer
            </Tooltip>
          </label>
        </div>
      </div>

      {values.itContact === true ? (
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Name
            </label>
            <input
              autoComplete="off"
              name="itContactName"
              id="itContactName"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.itContactName) && touched.itContactName,
              })}
              value={values.itContactName}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="itContactName" />
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-3">
            <label className="form-label fs-4 d-block required fw-bold ps-1">
              Contact Phone Type
            </label>
            {Array.from(contactTypeDB).map((value: any, id) => (
              <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                <label className="form-check-label fs-5 mb-0 ms-2" key={id}>
                  <input
                    id="itContactPhoneType"
                    className="form-check-input"
                    type="radio"
                    name="itContactPhoneType"
                    value={value.id}
                    checked={values.itContactPhoneType === value.id}
                    onChange={() =>
                      setFieldValue("itContactPhoneType", value.id)
                    }
                  />
                  <span className="form-check-label fs-5 mb-0 ms-2">
                    {value.name}
                  </span>
                </label>
              </div>
            ))}
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="itContactPhoneType" />
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Phone
            </label>
            <input
              autoComplete="off"
              id="itContactPhoneNumber"
              name="itContactPhoneNumber"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.itContactPhoneNumber) &&
                  touched.itContactPhoneNumber,
              })}
              value={values.itContactPhoneNumber}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="itContactPhoneNumber" />
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label required fs-4 fw-bold">
              Contact Email
            </label>
            <input
              autoComplete="off"
              id="itContactEmail"
              name="itContactEmail"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.itContactEmail) && touched.itContactEmail,
              })}
              value={values.itContactEmail}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="itContactEmail" />
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Name Role
            </label>
            <input
              autoComplete="off"
              id="itContactNameRole"
              name="itContactNameRole"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.itContactNameRole) &&
                  touched.itContactNameRole,
              })}
              value={values.itContactNameRole}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="itContactNameRole" />
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-2 pe-xl-5 ps-xl-5 pb-2">
          <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 align-items-center">
            <input
              id="serviceContact"
              type="checkbox"
              name="serviceContact"
              className="form-check-input"
              value="serviceContact"
              checked={values.serviceContact}
              onChange={handleChange}
            />
            <span
              className="addServiceContact form-label fw-bold mt-2 ms-3 fs-3"
              style={{ cursor: "pointer" }}
            >
              Add Service Contact
            </span>
            <i
              className="addServiceContact fas fa-exclamation-circle ms-2 mt-1 fs-7"
              data-bs-toggle="tooltip"
            ></i>
             <Tooltip anchorSelect=".addServiceContact" className="tooltip-dashboard">
             Click here to enter the contact details of the person or department in charge of technical issues.
            </Tooltip>
          </label>
        </div>
      </div>

      {values.serviceContact === true ? (
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Name
            </label>
            <input
              autoComplete="off"
              name="serviceContactName"
              id="serviceContactName"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.serviceContactName) &&
                  touched.serviceContactName,
              })}
              value={values.serviceContactName}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="serviceContactName" />
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-3">
            <label className="form-label d-block fs-4 required fw-bold ps-1">
              Contact Phone Type
            </label>
            {Array.from(contactTypeDB).map((value: any, id) => (
              <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                <label className="form-check-label fs-5 mb-0 ms-2" key={id}>
                  <input
                    id="serviceContactPhoneType"
                    className="form-check-input"
                    type="radio"
                    name="serviceContactPhoneType"
                    value={value.id}
                    checked={values.serviceContactPhoneType === value.id}
                    onChange={() =>
                      setFieldValue("serviceContactPhoneType", value.id)
                    }
                  />
                  <span className="form-check-label fs-5 mb-0 ms-2">
                    {value.name}
                  </span>
                </label>
              </div>
            ))}
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="serviceContactPhoneType" />
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Phone
            </label>
            <input
              autoComplete="off"
              id="serviceContactPhoneNumber"
              name="serviceContactPhoneNumber"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.serviceContactPhoneNumber) &&
                  touched.serviceContactPhoneNumber,
              })}
              value={values.serviceContactPhoneNumber}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="serviceContactPhoneNumber" />
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label required fs-4 fw-bold">
              Contact Email
            </label>
            <input
              autoComplete="off"
              id="serviceContactEmail"
              name="serviceContactEmail"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.serviceContactEmail) &&
                  touched.serviceContactEmail,
              })}
              value={values.serviceContactEmail}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="serviceContactEmail" />
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Name Role
            </label>
            <input
              autoComplete="off"
              id="serviceContactNameRole"
              name="serviceContactNameRole"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.serviceContactNameRole) &&
                  touched.serviceContactNameRole,
              })}
              value={values.serviceContactNameRole}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="serviceContactNameRole" />
            </div>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-2 pe-xl-5 ps-xl-5 pb-2">
          <label className="form-check form-check-custom form-check-solid me-5 d-flex mt-1 align-items-center">
            <input
              id="storeManagerContact"
              type="checkbox"
              name="storeManagerContact"
              className="form-check-input"
              value="storeManagerContact"
              checked={values.storeManagerContact}
              onChange={handleChange}
            />
            <span
              className="addStoreManagerContact form-label fw-bold mt-2 ms-3 fs-3"
              style={{ cursor: "pointer" }}
            >
              Add Store Manager Contact
            </span>
            <i
              className="addStoreManagerContact fas fa-exclamation-circle ms-2 mt-1 fs-7"
              data-bs-toggle="tooltip"
            ></i>
              <Tooltip anchorSelect=".addStoreManagerContact" className="tooltip-dashboard">
              Click here to input the contact information of the store manager.
            </Tooltip>
          </label>
        </div>
      </div>
      {values.storeManagerContact === true ? (
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Name
            </label>
            <input
              autoComplete="off"
              name="storeManagerContactName"
              id="storeManagerContactName"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.storeManagerContactName) &&
                  touched.storeManagerContactName,
              })}
              value={values.storeManagerContactName}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="storeManagerContactName" />
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-3">
            <label className="form-label d-block fs-4 required fw-bold ps-1">
              Contact Phone Type
            </label>
            {Array.from(contactTypeDB).map((value: any, id) => (
              <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                <label className="form-check-label fs-5 mb-0 ms-2" key={id}>
                  <input
                    id="typeOfStoreManagerContactPhoneNumber"
                    className="form-check-input"
                    type="radio"
                    name="typeOfStoreManagerContactPhoneNumber"
                    value={value.id}
                    checked={
                      values.typeOfStoreManagerContactPhoneNumber === value.id
                    }
                    onChange={() =>
                      setFieldValue(
                        "typeOfStoreManagerContactPhoneNumber",
                        value.id
                      )
                    }
                  />
                  <span className="form-check-label fs-5 mb-0 ms-2">
                    {value.name}
                  </span>
                </label>
              </div>
            ))}
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="typeOfStoreManagerContactPhoneNumber" />
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label fs-4 required fw-bold">
              Contact Phone
            </label>
            <input
              autoComplete="off"
              id="storeManagerContactPhoneNumber"
              name="storeManagerContactPhoneNumber"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.storeManagerContactPhoneNumber) &&
                  touched.storeManagerContactPhoneNumber,
              })}
              value={values.storeManagerContactPhoneNumber}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="storeManagerContactPhoneNumber" />
            </div>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
            <label className="form-label required fs-4 fw-bold">
              Contact Email
            </label>
            <input
              autoComplete="off"
              id="storeManagerContactEmail"
              name="storeManagerContactEmail"
              className={clsx("form-control", {
                "is-invalid":
                  Boolean(errors.storeManagerContactEmail) &&
                  touched.storeManagerContactEmail,
              })}
              value={values.storeManagerContactEmail}
              onChange={handleChange}
            />
            <div className="text-danger text-align-top-center mt-2">
              <ErrorMessage name="storeManagerContactEmail" />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
